import { createSlice } from "@reduxjs/toolkit";

import { reducerUtils, handleAsyncAction } from "utils/asyncUtils";

const name = "plant";

const initialState = {
  plantlist: reducerUtils.init(),
};

export const plant = createSlice({
  name,
  initialState,
  reducers: {
    getPlantList: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => {
        return action.type.includes(name);
      },
      (state, action) => {
        //console.log({ state });
        //console.log({ action });
        state[action.payload.stateType] = handleAsyncAction(action);
      }
    );
  },
});

export const { getPlantList } = plant.actions;
export default plant.reducer;
