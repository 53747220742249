import { takeEvery } from "redux-saga/effects";
import { createPromiseSaga } from "utils/asyncUtils";

import { getIgState } from "../reducers/deviceSlice";
import { apiIgState } from "apis/deviceApi";

const handleIgState = createPromiseSaga(getIgState, apiIgState, "igstate");

export function* deviceSaga() {
  yield takeEvery(getIgState, handleIgState);
}

export default deviceSaga;
