import { combineReducers } from "redux";

import menu from "./menuSlice";
import auth from "./authSlice";
import dash from "./dashSlice";
import device from "./deviceSlice";
import plant from "./plantSlice";
//import { authReducer } from "./auth";
//import { authenticateReducer } from "./authenticateSlice";

const reducers = combineReducers({ menu, auth, dash, device, plant });

export default reducers;
