import { takeEvery } from "redux-saga/effects";
import { createPromiseSaga } from "utils/asyncUtils";
import { login, logout, checkAuth } from "../reducers/authSlice";
import { signin, signout, account } from "apis/authApi";

const handleSignin = createPromiseSaga(login, signin, "login"); // 5. api 상태에 따라 적합한 action을 dispatch하는 saga 생성
const handleSignout = createPromiseSaga(logout, signout);
const handleAccount = createPromiseSaga(checkAuth, account, "login");

export function* authSaga() {
  yield takeEvery(login, handleSignin);
  yield takeEvery(logout, handleSignout);
  yield takeEvery(checkAuth, handleAccount);
}

export default authSaga;

/*import { all, call, fork, put, take } from "redux-saga/effects";
import {
  signinRequest,
  signinSuccess,
  signinFailed,
  signoutRequest,
  checkAuthRequest,
  checkAuthSuccess,
  checkAuthFailed,
} from "store/reducers/authSlice";
import { signin, signout, authenticate } from "apis/authApi";
import { consts } from "common";

function* handleSigin(action) {
  try {
    const response = yield call(signin, action.payload);

    if (response.status === 200) {
      localStorage.setItem(consts.ACCESS_TOKEN, response.data?.jwtToken);

      yield put(signinSuccess(response));
    } else {
      yield put(signinFailed(response.status));
    }
  } catch (e) {
    const { status } = e.response || 400;
    yield put(signinFailed({ status }));
  }
}

function* handleSignout() {
  try {
    yield call(signout);
    localStorage.removeItem(consts.ACCESS_TOKEN);
  } catch (e) {}
}

function* handleAuthenticate() {
  try {
    const response = yield call(authenticate);
    yield put(checkAuthSuccess(response));
  } catch (e) {
    const { status } = e.response || 400;
    yield put(checkAuthFailed({ status }));
  }
}

function* watchSignin() {
  while (true) {
    const action = yield take(signinRequest);
    yield call(handleSigin, action);
  }
}

function* watchSignout() {
  while (true) {
    yield take(signoutRequest);
    yield call(handleSignout);
  }
}

function* watchAuthenticate() {
  while (true) {
    yield take(checkAuthRequest);
    yield call(handleAuthenticate);
  }
}

export default function* authSaga() {
  yield all([fork(watchSignin), fork(watchSignout), fork(watchAuthenticate)]);
}
*/

// import { all, call, fork, put, take } from "redux-saga/effects";

// import {
//   authenticateRequest,
//   authenticateSuccess,
//   authenticateFailer,
//   signinRequest,
//   signoutRequest,
//   signoutSuccess,
//   signoutFailer,
// } from "../reducers/authSlice";

// import { axios, consts } from "common";

// function apiAuthenticate() {
//   return axios.get("/accounts");
// }

// function apiSignin(requestBody) {
//   return axios.post("/accounts/authenticate", requestBody, { forGuest: true });
// }

// function apiSignout() {
//   return axios.post("/accounts/signout");
// }

// function* asyncAuthenticate(token) {
//   try {
//     console.log({ token });

//     const response = yield call(apiAuthenticate);

//     if (response?.status === 200) {
//       //console.log({ response });
//       //yield localStorage.setItem("ACCESS_TOKEN", response.data.jwtToken);
//       yield put(authenticateSuccess(response));
//     } else {
//       yield put(authenticateFailer());
//     }
//   } catch (e) {
//     yield put(authenticateFailer());
//   }
// }

// function* asyncSignin(action) {
//   try {
//     const response = yield call(apiSignin, {
//       id: String(action.payload.id).trim(),
//       password: String(action.payload.password).trim(),
//     });

//     if (response?.status === 200) {
//       yield localStorage.setItem(consts.ACCESS_TOKEN, response.data.jwtToken);
//       yield put(authenticateSuccess(response));
//     } else {
//       yield put(authenticateFailer());
//     }
//   } catch (e) {
//     yield put(authenticateFailer());
//   }
// }

// function* asyncSignout() {
//   try {
//     yield call(apiSignout);
//     localStorage.removeItem(consts.ACCESS_TOKEN);
//     yield put(signoutSuccess());
//   } catch (e) {
//     yield put(signoutFailer());
//   }
// }

// // action 호출 감시 watch 함수
// function* watchAuthenticate() {
//   while (true) {
//     //const action = yield take(authenticateRequest);
//     yield take(authenticateRequest);
//     yield call(asyncAuthenticate);
//   }
// }

// function* watchSignin() {
//   while (true) {
//     const action = yield take(signinRequest);
//     yield call(asyncSignin, action);
//   }
// }

// function* watchSignout() {
//   while (true) {
//     yield take(signoutRequest);
//     yield call(asyncSignout);
//   }
// }

// export default function* authSaga() {
//   yield all([fork(watchAuthenticate), fork(watchSignin), fork(watchSignout)]);
// }

// function apiGetMember(id) {
//   return axios.get(`/accounts/getmember/${id}`);
// }

// function* asyncGetMember(action) {
//   try {
//     const response = yield call(apiGetMember, action.payload);

//     if (response?.status === 200) {
//       yield put(authActions.setMember(response));
//     }
//   } catch (e) {
//     console.error(e);
//   }
// }

// // action 감시 함수
// function* watchGetMember() {
//   while (true) {
//     const action = yield take(authActions.setMember);
//     yield call(asyncGetMember, action);
//   }
// }

// export default function* authSaga() {
//   yield all([fork(watchGetMember)]);
// }
