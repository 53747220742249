import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";

const control = {
  id: "group-control",
  title: "Control",
  admin: false,
  type: "group",
  children: [
    {
      id: "control",
      title: "제어관리",
      type: "item",
      url: "/control",
      icon: SettingsOutlinedIcon,
      breadcrumbs: false,
    },

    {
      id: "control-history",
      title: "제어기록",
      type: "item",
      url: "/control/history",
      icon: ManageHistoryOutlinedIcon,
      breadcrumbs: false,
    },
  ],
};

export default control;
