import { lazy } from "react";

import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
//import ProtectedRoute from "components/ProtectedRoute";
import ProtectedRoute from "../routes/ProtectedRoute";

const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));
const Respectively = Loadable(
  lazy(() => import("pages/dashboard/Respectively"))
);
const Predict = Loadable(lazy(() => import("pages/dashboard/Predict")));

// Measure
const MeasureEnergy = Loadable(
  lazy(() => import("pages/measure/MeasureEnergy"))
);
const MeasureSystem = Loadable(
  lazy(() => import("pages/measure/MeasureSystem"))
);

const MeasureJb = Loadable(lazy(() => import("pages/measure/MeasureJb")));

const MeasureEvent = Loadable(lazy(() => import("pages/measure/MeasureEvent")));

// 제어 관리 기능
const ControlCmd = Loadable(lazy(() => import("pages/control/ControlCmd")));
const ControlHistory = Loadable(
  lazy(() => import("pages/control/ControlHistory"))
);

// 보고서 기능
const StatisticsDaily = Loadable(
  lazy(() => import("pages/statistics/StatisticsDaily"))
);
const StatisticsWeekly = Loadable(
  lazy(() => import("pages/statistics/StatisticsWeekly"))
);
const StatisticsMonthly = Loadable(
  lazy(() => import("pages/statistics/StatisticsMonthly"))
);
const StatisticsYearly = Loadable(
  lazy(() => import("pages/statistics/StatisticsYearly"))
);
const StatisticsTimes = Loadable(
  lazy(() => import("pages/statistics/StatisticsTimes"))
);
const StatisticsPredict = Loadable(
  lazy(() => import("pages/statistics/StatisticsPredict"))
);

// Record
const RecordInverter = Loadable(
  lazy(() => import("pages/record/RecordInverter"))
);
const RecordGrid = Loadable(lazy(() => import("pages/record/RecordGrid")));
const RecordJoinBox = Loadable(
  lazy(() => import("pages/record/RecordJoinBox"))
);
const RecordEnviroment = Loadable(
  lazy(() => import("pages/record/RecordEnviroment"))
);
const RecordEvent = Loadable(lazy(() => import("pages/record/RecordEvent")));
const RecordOperation = Loadable(
  lazy(() => import("pages/record/RecordOperation"))
);

// admin
const AdminPlantList = Loadable(lazy(() => import("pages/admin/PlantList")));
const AdminPlantCreate = Loadable(
  lazy(() => import("pages/admin/PlantCreate"))
);
const AdminUserList = Loadable(lazy(() => import("pages/admin/UserList")));
const AdminUserCreate = Loadable(lazy(() => import("pages/admin/UserCreate")));

// 디바이스 관리
const AdminDeviceList = Loadable(lazy(() => import("pages/admin/DeviceList")));
const AdminDeviceCreate = Loadable(
  lazy(() => import("pages/admin/DeviceCreate"))
);

// 미사용 접속반 채널 등록 페이지
const AdminUnuseJb = Loadable(lazy(() => import("pages/admin/UnuseJb")));

const AdminPlantUser = Loadable(lazy(() => import("pages/admin/PlantUser")));
const AdminPlantDevice = Loadable(
  lazy(() => import("pages/admin/PlantDevice"))
);
const AdminPlantDeviceCreate = Loadable(
  lazy(() => import("pages/admin/PlantDeviceCreate"))
);

const AdminPlantWizbee = Loadable(
  lazy(() => import("pages/admin/PlantWizbee"))
);

const Regular = Loadable(lazy(() => import("pages/onm/Regular")));
const RegularCheck = Loadable(lazy(() => import("pages/onm/RegularCheck")));
const RegularView = Loadable(lazy(() => import("pages/onm/RegularView")));
const Safety = Loadable(lazy(() => import("pages/onm/Safety")));
const SafetyCheck = Loadable(lazy(() => import("pages/onm/SafetyCheck")));
const SafetyView = Loadable(lazy(() => import("pages/onm/SafetyView")));

// 유지 관리
const Facility = Loadable(
  lazy(() => import("../pages/admin/facility/Facility"))
);
const FacilityAccept = Loadable(
  lazy(() => import("../pages/admin/facility/FacilityAccept"))
);
const FailictyView = Loadable(
  lazy(() => import("../pages/admin/facility/FacilityView"))
);

const ChartTest = Loadable(lazy(() => import("../pages/ChartTest")));

const RegionTest = Loadable(lazy(() => import("../pages/dashboard/Region")));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <DashboardDefault />
        </ProtectedRoute>
      ),
    },
    {
      path: "dashboard",
      element: (
        <ProtectedRoute>
          <DashboardDefault />
        </ProtectedRoute>
      ),
    },
    {
      path: "dashboard/respectively",
      element: (
        <ProtectedRoute>
          <Respectively />
        </ProtectedRoute>
      ),
    },
    { path: "dashboard/predict", element: <Predict /> },

    { path: "dashboard/region", element: <RegionTest /> },

    {
      path: "measure",
      element: (
        <ProtectedRoute>
          <MeasureEnergy />
        </ProtectedRoute>
      ),
    },

    {
      path: "measure/system",
      element: (
        <ProtectedRoute>
          <MeasureSystem />
        </ProtectedRoute>
      ),
    },

    {
      path: "measure/jb",
      element: (
        <ProtectedRoute>
          <MeasureJb />
        </ProtectedRoute>
      ),
    },

    {
      path: "measure/event",
      element: (
        <ProtectedRoute>
          <MeasureEvent />
        </ProtectedRoute>
      ),
    },

    {
      path: "control",
      element: (
        <ProtectedRoute>
          <ControlCmd />
        </ProtectedRoute>
      ),
    },
    {
      path: "control/history",
      element: (
        <ProtectedRoute>
          <ControlHistory />
        </ProtectedRoute>
      ),
    },

    {
      path: "statistics/daily",
      element: (
        <ProtectedRoute>
          <StatisticsDaily />
        </ProtectedRoute>
      ),
    },

    {
      path: "statistics/weekly",
      element: (
        <ProtectedRoute>
          <StatisticsWeekly />
        </ProtectedRoute>
      ),
    },

    {
      path: "statistics/monthly",
      element: (
        <ProtectedRoute>
          <StatisticsMonthly />
        </ProtectedRoute>
      ),
    },

    {
      path: "statistics/yearly",
      element: (
        <ProtectedRoute>
          <StatisticsYearly />
        </ProtectedRoute>
      ),
    },

    {
      path: "statistics/times",
      element: (
        <ProtectedRoute>
          <StatisticsTimes />
        </ProtectedRoute>
      ),
    },

    {
      path: "statistics/predict",
      element: (
        <ProtectedRoute>
          <StatisticsPredict />
        </ProtectedRoute>
      ),
    },

    {
      path: "record/inverter",
      element: (
        <ProtectedRoute>
          <RecordInverter />
        </ProtectedRoute>
      ),
    },

    {
      path: "record/grid",
      element: (
        <ProtectedRoute>
          <RecordGrid />
        </ProtectedRoute>
      ),
    },

    {
      path: "record/joinbox",
      element: (
        <ProtectedRoute>
          <RecordJoinBox />
        </ProtectedRoute>
      ),
    },

    {
      path: "record/enviroment",
      element: (
        <ProtectedRoute>
          <RecordEnviroment />
        </ProtectedRoute>
      ),
    },

    {
      path: "record/event",
      element: (
        <ProtectedRoute>
          <RecordEvent />
        </ProtectedRoute>
      ),
    },

    {
      path: "record/operation",
      element: (
        <ProtectedRoute>
          <RecordOperation />
        </ProtectedRoute>
      ),
    },

    {
      path: "admin/plant",
      element: (
        <ProtectedRoute>
          <AdminPlantList />
        </ProtectedRoute>
      ),
    },

    {
      path: "admin/plant/create",
      element: (
        <ProtectedRoute>
          <AdminPlantCreate />
        </ProtectedRoute>
      ),
    },

    {
      path: "admin/plant/user",
      element: (
        <ProtectedRoute>
          <AdminPlantUser />
        </ProtectedRoute>
      ),
    },

    {
      path: "admin/plant/device",
      element: (
        <ProtectedRoute>
          <AdminPlantDevice />
        </ProtectedRoute>
      ),
    },

    {
      path: "admin/plant/device/create",
      element: (
        <ProtectedRoute>
          <AdminPlantDeviceCreate />
        </ProtectedRoute>
      ),
    },

    {
      path: "admin/plant/wizbee",
      element: (
        <ProtectedRoute>
          <AdminPlantWizbee />
        </ProtectedRoute>
      ),
    },

    {
      path: "admin/user",
      element: (
        <ProtectedRoute>
          <AdminUserList />
        </ProtectedRoute>
      ),
    },

    {
      path: "admin/user/create",
      element: (
        <ProtectedRoute>
          <AdminUserCreate />
        </ProtectedRoute>
      ),
    },

    {
      path: "admin/device",
      element: (
        <ProtectedRoute>
          <AdminDeviceList />
        </ProtectedRoute>
      ),
    },

    {
      path: "admin/device/create",
      element: (
        <ProtectedRoute>
          <AdminDeviceCreate />
        </ProtectedRoute>
      ),
    },

    {
      path: "admin/device/unusejb",
      element: (
        <ProtectedRoute>
          <AdminUnuseJb />
        </ProtectedRoute>
      ),
    },

    {
      path: "/admin/facility",
      element: (
        <ProtectedRoute isManager>
          <Facility />
        </ProtectedRoute>
      ),
    },

    {
      path: "/admin/facility/accept",
      element: (
        <ProtectedRoute>
          <FacilityAccept />
        </ProtectedRoute>
      ),
    },

    {
      path: "/admin/facility/view",
      element: (
        <ProtectedRoute>
          <FailictyView />
        </ProtectedRoute>
      ),
    },

    {
      path: "onm/regular",
      element: (
        <ProtectedRoute>
          <Regular />
        </ProtectedRoute>
      ),
    },
    {
      path: "onm/regular/check",
      element: (
        <ProtectedRoute>
          <RegularCheck />
        </ProtectedRoute>
      ),
    },
    {
      path: "onm/regular/view",
      element: (
        <ProtectedRoute>
          <RegularView />
        </ProtectedRoute>
      ),
    },

    {
      path: "onm/safety",
      element: (
        <ProtectedRoute>
          <Safety />
        </ProtectedRoute>
      ),
    },
    {
      path: "onm/safety/check",
      element: (
        <ProtectedRoute>
          <SafetyCheck />
        </ProtectedRoute>
      ),
    },
    {
      path: "onm/safety/view",
      element: (
        <ProtectedRoute>
          <SafetyView />
        </ProtectedRoute>
      ),
    },

    { path: "/charttest", element: <ChartTest /> },
  ],
};

export default MainRoutes;
