import BatteryCharging30OutlinedIcon from "@mui/icons-material/BatteryCharging30Outlined";
import ElectricBoltOutlinedIcon from "@mui/icons-material/ElectricBoltOutlined";
import VerticalSplitOutlinedIcon from "@mui/icons-material/VerticalSplitOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import AddAlertOutlinedIcon from "@mui/icons-material/AddAlertOutlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";

const record = {
  id: "group-Record",
  title: "데이터 조회",
  admin: false,
  type: "group",
  children: [
    {
      id: "record-inverter",
      title: "인버터",
      type: "item",
      url: "/record/inverter",
      icon: BatteryCharging30OutlinedIcon,
      breadcrumbs: true,
    },

    {
      id: "record-grid",
      title: "수배전반",
      type: "item",
      url: "/record/grid",
      icon: ElectricBoltOutlinedIcon,
      breadcrumbs: true,
    },

    {
      id: "record-joinbox",
      title: "접속반",
      type: "item",
      url: "/record/joinbox",
      icon: VerticalSplitOutlinedIcon,
      breadcrumbs: true,
    },

    {
      id: "record-enviroment",
      title: "기상센서",
      type: "item",
      url: "/record/enviroment",
      icon: WbSunnyOutlinedIcon,
      breadcrumbs: true,
    },

    {
      id: "record-event",
      title: "경보및알람",
      type: "item",
      url: "/record/event",
      icon: AddAlertOutlinedIcon,
      breadcrumbs: true,
    },

    {
      id: "record-operation",
      title: "운영기록",
      type: "item",
      url: "/record/operation",
      icon: ManageHistoryOutlinedIcon,
      breadcrumbs: true,
    },
  ],
};

export default record;
