/* eslint-disable no-unused-vars */
import { Box, IconButton, Link, useMediaQuery, Button } from "@mui/material";
import { GithubOutlined } from "@ant-design/icons";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";

import Search from "./Search";
import Profile from "./Profile";
import Notification from "./Notification";
import MobileSection from "./MobileSection";

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      {!matchesXs && <Box sx={{ width: "100%", ml: { xs: 0, md: 1 } }}></Box>}
      {/* {!matchesXs && <Search />} */}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}
      {/* <IconButton>


      </IconButton> */}
      {/* <IconButton
        component={Link}
        href="https://github.com/codedthemes/mantis-free-react-admin-template"
        target="_blank"
        disableRipple
        color="secondary"
        title="Download Free Version"
        sx={{ color: "text.primary", bgcolor: "grey.100" }}
      >
        <GithubOutlined />
      </IconButton>

      
      <Notification />
      // {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />} */}

      {/* <IconButton>
        <ArticleOutlinedIcon />
      </IconButton>
      <Notification /> */}
      {!matchesXs && <Profile />}
    </>
  );
};

export default HeaderContent;
