import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

const onm = {
  id: "group-onm",
  title: "O&M",
  admin: false,
  type: "group",
  children: [
    {
      id: "regular",
      title: "상시점검",
      type: "item",
      url: "/onm/regular",
      icon: InsertInvitationOutlinedIcon,
      breadcrumbs: true,
    },
    {
      id: "safety",
      title: "안전관리",
      type: "item",
      url: "/onm/safety",
      icon: ContentCopyOutlinedIcon,
      breadcrumbs: true,
    },
  ],
};

export default onm;
