import { signin, signout } from "apis/authApi";

//const consts.TOKEN_KEY = "_t4gd-*-";
import { consts } from "common";

export const auth = {
  isAuthenticated,
  getToken,
  setToken,
  setRefreshToken,
  getRefreshToken,
  clearToken,
  login,
  logout,
  setUserId,
  getUserId,
  delUserId,
};

function isAuthenticated() {
  return !!getToken();
}

function getToken() {
  return (
    sessionStorage.getItem(consts.TOKEN_KEY) ||
    localStorage.getItem(consts.TOKEN_KEY)
  );
}

function setToken({ token, rememberMe = false }) {
  if (rememberMe) {
    localStorage.setItem(consts.TOKEN_KEY, token);
  } else {
    sessionStorage.setItem(consts.TOKEN_KEY, token);
  }
}

function setRefreshToken(token) {
  if (token) {
    localStorage.setItem(consts.REFRESH_KEY, token);
  }
}

function getRefreshToken() {
  return localStorage.getItem(consts.REFRESH_KEY);
}

async function login({ id, password }) {
  const { token, user } = await signin({ id, password });

  localStorage.setItem(consts.TOKEN_KEY, token);

  return { token, user };
}

async function logout() {
  await signout();
  localStorage.removeItem(consts.TOKEN_KEY);
  sessionStorage.removeItem(consts.TOKEN_KEY);
}

async function clearToken() {
  localStorage.removeItem(consts.TOKEN_KEY);
  sessionStorage.removeItem(consts.TOKEN_KEY);
}

async function setUserId(value) {
  localStorage.setItem(consts.TOKEN_SAVEID, value);
}

async function getUserId() {
  return localStorage.getItem(consts.TOKEN_SAVEID);
}

async function delUserId() {
  localStorage.removeItem(consts.TOKEN_SAVEID);
}
