import { map } from "ramda";
import { all, fork } from "redux-saga/effects";
import authSaga from "./authSaga";
import dashSaga from "./dashSaga";
import deviceSaga from "./deviceSaga";
import plantSaga from "./plantSaga";
//import authenticateSaga from "./authenticateSaga";

let combineSagas = {};
combineSagas = Object.assign(combineSagas, {
  authSaga,
  dashSaga,
  deviceSaga,
  plantSaga,
});

export default function* rootSaga() {
  yield all(map(fork, combineSagas));
}
