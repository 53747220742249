import { useLayoutEffect, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export const useScrollRestoration = () => {
  const location = useLocation();
  const scrollPositions = useRef({});

  //console.log({ scrollPositions });

  useLayoutEffect(() => {
    // 현재 스크롤 위치 저장
    //console.log("현재 스크롤 위치 " + window.scrollY);
    //console.log({ window, document });
    //scrollPositions.current[location.key] = window.scrollY;

    // 이전 페이지로 돌아갔을때, 저장된 스크롤 위치로 복원
    const restoreScrollPosition = () => {
      //console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~1");
      const scrollY = scrollPositions.current[location.key];

      //console.log({ scrollPositions, location, scrollY });

      //console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~1");

      if (scrollPositions.current[location.key] !== undefined) {
        //console.log("devined : " + scrollPositions.current[location.key]);

        setTimeout(() => {
          //console.log("scrollTo: start");
          //window.scrollTo(0, 300);
          //console.log("setTimeout~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
          //const el = document.getElementById("root"); // id of the parent
          //el.scrollTo(0, 500);
          window.scrollTo(0, scrollY);
        }, 300);

        // window.scroll({
        //   top: 0,
        //   scrollY: scrollPositions.current[location.key],
        //   behavior: "smooth",
        // });
        //window.scrollTo(0, 300);
        // window.scrollTo({
        //   top: 100,
        //   left: 100,
        //   behavior: "smooth",
        // });
        //window.scrollBy(0, 100);
      }
      //console.log("restoreScollPosition");
    };
    restoreScrollPosition();
  }, [location]);

  useEffect(() => {
    const scroll = (event) => {
      const { scrollY } = window;
      // console.group("onScoll~~~~~~~~~~~");
      // console.log({ scrollY, window, event }, location.key);
      // console.groupEnd("onScoll~~~~~~~~~~~");
      scrollPositions.current[location.key] = scrollY;
    };

    window.addEventListener("scroll", scroll);
    return () => window.removeEventListener("scroll", scroll);
  }, [location]);
};
