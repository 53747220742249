import { axios } from "common";

export const signin = ({ id, password }) => {
  return axios.post(
    "/accounts/authenticate",
    { id, password },
    { forGuest: true }
  );
};

export const signout = () => {
  return axios.post("/accounts/signout");
};

export const account = () => {
  return axios.get("/accounts");
};

// import { NETWORK_LATENCY } from "utils/constants";```````````````````````````````

// export const signin = ({ id, password }) => {
//   return new Promise((resolve, reject) => {
//     setTimeout(async () => {
//       try {
//         const { status, data } = await axios.post(
//           "/accounts/authenticate",
//           { id, password },
//           { forGuest: true }
//         );

//         if (status === 200) {
//           const token = data.jwtToken;
//           const user = data;
//           return resolve({ token, user });
//         }
//       } catch (e) {
//         return reject(e);
//       }
//     }, NETWORK_LATENCY);
//   });
// };

// export const signout = () => {
//   return new Promise((resolve) => {
//     setTimeout(async () => {
//       await axios.post("/accounts/signout");
//     }, NETWORK_LATENCY);

//     resolve();
//   });
// };
