import { createSlice } from "@reduxjs/toolkit";

import { reducerUtils, handleAsyncAction } from "utils/asyncUtils";

const name = "dash";

const initialState = {
  energy: reducerUtils.init(),
  hourly: reducerUtils.init(),
};

export const dash = createSlice({
  name,
  initialState,
  reducers: {
    getEnergy: (state, action) => {},
    getHourly: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => {
        return action.type.includes(name);
      },
      (state, action) => {
        state[action.payload.stateType] = handleAsyncAction(action);
      }
    );
  },
});

export const { getEnergy, getHourly } = dash.actions;
export default dash.reducer;
