import { createSlice } from "@reduxjs/toolkit";

import { reducerUtils, handleAsyncAction } from "utils/asyncUtils";

const name = "device";

const initialState = {
  igstate: reducerUtils.init(),
};

export const device = createSlice({
  name,
  initialState,
  reducers: {
    getIgState: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => {
        return action.type.includes(name);
      },
      (state, action) => {
        state[action.payload.stateType] = handleAsyncAction(action);
      }
    );
  },
});

export const { getIgState } = device.actions;
export default device.reducer;
