import { axios } from "common";

export const apiEnergy = ({ mappId }) => {
  return axios.get("/dashboard/state-energy", {
    params: {
      mappId,
    },
  });
};

export const apiHourly = ({ mappId }) => {
  return axios.get("/dashboard/state-hourchart", {
    params: { mappId },
  });
};
