const Logo = () => {
  return (
    <>
      <svg
        width="118"
        height="35"
        viewBox="0 0 118 35"
        fill="none"
        version="1.1"
        id="svg57"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs id="defs55">
          <linearGradient
            id="paint0_linear"
            x1="8.62526"
            y1="14.0888"
            x2="5.56709"
            y2="17.1469"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0,-40)"
          >
            <stop stopColor="#ef3823" id="stop45" />
            <stop
              offset="0.9637"
              stopColor="#ef3823"
              stopOpacity="0"
              id="stop47"
            />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="26.2675"
            y1="14.1279"
            x2="28.7404"
            y2="16.938"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(0,-40)"
          >
            <stop stopColor="#ef3823" id="stop50" />
            <stop offset="1" stopColor="#ef3823" stopOpacity="0" id="stop52" />
          </linearGradient>
        </defs>
        <g
          id="g3438"
          transform="matrix(0.45047964,0,0,-0.44601327,18.622525,19.856728)"
          fill="#ee3823"
        >
          <path
            d="m 0,0 -15.617,5.374 -9.661,19.58 15.617,-5.373 z"
            fill="#ee3823"
            id="path3440"
          />
        </g>
        <g
          id="g3442"
          transform="matrix(0.45047964,0,0,-0.44601327,25.194257,19.856728)"
          fill="#ee3823"
        >
          <path
            d="M 0,0 15.618,5.374 25.279,24.954 9.661,19.581 Z"
            fill="#ee3823"
            id="path3444"
          />
        </g>
        <path
          d="m 33.193604,30.486337 -6.963514,-3.03824 -3.460585,-7.397131 6.840984,3.038242 z"
          fill="#ee3823"
          id="path3448"
        />
        <g
          id="g3450"
          transform="matrix(0.45047964,0,0,-0.44601327,25.570858,11.471723)"
          fill="#ee3823"
        >
          <path
            d="M 0,0 -8.13,-17.198 -16.26,0 -8.13,17.199 Z"
            fill="#ee3823"
            id="path3452"
          />
        </g>
        <path
          d="m 10.623403,30.486354 6.963514,-3.038244 3.460134,-7.397128 -6.840983,3.038242 z"
          fill="#ee3823"
          id="path3456"
        />
        <text
          fontFamily="sans-serif"
          fontWeight="bold"
          fontSize={18}
          fill="#262626"
          x="42.453049"
          y="24.976473"
          id="text3383"
        >
          <tspan id="tspan3381" x="42.453049" y="24.976473">
            WizOne
          </tspan>
        </text>
      </svg>
    </>
  );
};

export default Logo;
