import { lazy } from "react";

import Loadable from "components/Loadable";

const NotFound = Loadable(lazy(() => import("pages/errors/NotFound")));

const ErrorsRoutes = {
  path: "*",
  element: <NotFound />,
};

export default ErrorsRoutes;
