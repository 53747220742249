import { createSlice } from "@reduxjs/toolkit";

import { reducerUtils, handleAsyncAction } from "utils/asyncUtils";

const name = "auth";

const initialState = {
  login: reducerUtils.init(),
};

export const auth = createSlice({
  name,
  initialState,
  reducers: {
    login: (state, action) => {},
    logout: (state, action) => {},
    checkAuth: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => {
        return action.type.includes(name);
      },
      (state, action) => {
        state.login = handleAsyncAction(action);
      }
    );
  },
});

export const { login, logout, checkAuth } = auth.actions;
export default auth.reducer;
/*import { createSlice } from "@reduxjs/toolkit";

const name = "auth";

const initialState = {
  isInitializing: true,
  loading: "idle",
  error: null,
  loggedIn: false,
  loggedInUser: {},
  token: null,
};

const reducers = {
  signinRequest: (state, action) => {
    state.loading = "pending";
  },
  signinSuccess: (state, action) => {
    state.loading = "succeeded";
    state.loggedIn = true;
    state.token = action.payload?.data.token;
    state.loggedInUser = action.payload?.data;
  },
  signinFailed: (state, action) => {
    state.loading = "failed";
    state.loggedIn = false;
    state.error = action.payload;
  },
  signoutRequest: (state, action) => {
    state.loggedIn = false;
    state.loggedInUser = {};
  },

  checkAuthRequest: (state, action) => {
    state.loading = "idle";
  },

  checkAuthSuccess: (state, action) => {
    state.loading = "succeeded";
    state.loggedIn = true;
    state.loggedInUser = action.payload?.data ?? {};
  },

  checkAuthFailed: (state, action) => {
    state.loading = "failed";
    state.loggedIn = false;
    state.loggedInUser = {};
  },
};

export const auth = createSlice({
  name,
  initialState,
  reducers,
});

export const {
  signinRequest,
  signinSuccess,
  signinFailed,
  signoutRequest,
  checkAuthRequest,
  checkAuthSuccess,
  checkAuthFailed,
} = auth.actions;
export default auth.reducer;
*/
