import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import reducers from "./reducers";
import rootSaga from "./sagas";
import history from "./history";

const sagaMiddleware = createSagaMiddleware({
  context: { history: history },
});
const initialState = {};

const store = configureStore({
  reducer: reducers,
  middleware: [sagaMiddleware, logger],
  devTools: process.env.NODE_ENV !== "production",
  //devTools: true,
  preloadedState: initialState,
});

sagaMiddleware.run(rootSaga);

export default store;
