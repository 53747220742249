import { takeEvery } from "redux-saga/effects";
import { createPromiseSaga } from "utils/asyncUtils";

import { getPlantList } from "../reducers/plantSlice";
import { apiPlantList } from "apis/plantApi";

const handlePlantList = createPromiseSaga(
  getPlantList,
  apiPlantList,
  "plantlist"
);

export function* plantSaga() {
  yield takeEvery(getPlantList, handlePlantList);
}

export default plantSaga;
