import ThemeCustomization from "themes";
import Routes from "routes";
import { useScrollRestoration } from "hooks/useScrollRestoration";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import sameafter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(duration);
dayjs.extend(sameafter);

const App = () => {
  // 자동 스크롤 영역 복구 기능
  useScrollRestoration();

  return (
    <ThemeCustomization>
      {/* <ScrollTop> */}
      <Routes />
      {/* </ScrollTop> */}
    </ThemeCustomization>
  );
};

export default App;
