import { takeEvery } from "redux-saga/effects";
import { createPromiseSaga } from "utils/asyncUtils";

import { getEnergy, getHourly } from "../reducers/dashSlice";
import { apiEnergy, apiHourly } from "apis/dashApi";

const handleEnergy = createPromiseSaga(getEnergy, apiEnergy, "energy");
const handleHourly = createPromiseSaga(getHourly, apiHourly, "hourly");

export function* dashSaga() {
  yield takeEvery(getEnergy, handleEnergy);
  yield takeEvery(getHourly, handleHourly);
}

export default dashSaga;
