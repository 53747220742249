import { call, put } from "redux-saga/effects";

export const createActionString = (type) => {
  return { success: `${type}Success`, error: `${type}Error` };
};

export const createPromiseSaga = (type, promiseCreator, stateType = null) => {
  const { success, error } = createActionString(type); // 1

  return function* (action) {
    try {
      const response = yield call(promiseCreator, action.payload); // 2.

      // 아이디 자동 로그인 처리
      // 아이디 저장 처리 로직 들어가야 함.

      const payload = stateType ? { ...response, stateType } : response;

      yield put({
        type: success,
        payload,
      }); // 3.
    } catch (err) {
      const { status, data } = err.response || {
        status: 400,
        data: "Unknown error due to bad request",
      };

      yield put({
        type: error,
        payload: { status, data, stateType },
        error: true,
      }); // 4.
    }
  };
};

export const createPromiseSagaById = (type, promiseCreator, stateType) => {
  const { success, error } = createActionString(type); // 1

  return function* (action) {
    const id = action.meta;

    try {
      const response = yield call(promiseCreator, action.payload); // 2.

      const payload = stateType ? { ...response, stateType } : response;

      yield put({
        type: success,
        payload,
        media: id,
      }); // 3.
    } catch (err) {
      const { status, data } = err.response || { status: 400, data: "error" };

      yield put({
        type: error,
        payload: { status, data, stateType, meta: id },
        error: true,
      }); // 4.
    }
  };
};

export const reducerUtils = {
  init: () => ({
    data: null,
    loading: false,
    error: false,
  }),

  loading: (prevData = null) => ({
    data: prevData,
    loading: true,
    error: false,
  }),

  success: (data = null) => ({
    data: data,
    loading: false,
    error: false,
  }),

  error: (error) => ({
    data: error,
    loading: false,
    error: true,
  }),
};

export const handleAsyncAction = ({ type, payload = {} }, prevData = null) => {
  // success or error
  if (type.includes("Success")) return reducerUtils.success(payload); // 1.
  if (type.includes("Error")) return reducerUtils.error(payload); //2.
  // loading
  return reducerUtils.loading(prevData); // 3.
};
