import dashboard from "./dashboard";
import measure from "./measure";
import control from "./control";
import statistics from "./statistics";
import record from "./record";
import admin from "./admin";
import onm from "./onm";

const menuItems = {
  items: [dashboard, measure, control, statistics, record, onm, admin],
};

export default menuItems;
